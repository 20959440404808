//----------------------------------------------------------------------------
// ASIS - Information system of the Astronomical Institute
//----------------------------------------------------------------------------
// validators.js
// Exports a set of validators for form fields.
//
//----------------------------------------------------------------------------
// (c) Astronomical Institiute of the Czech Academy of Sciences
//----------------------------------------------------------------------------

import moment from 'moment';
import {validationMixin} from 'vuelidate';
import * as vv from 'vuelidate-validators';

// re-export validation mixin
export const vuelidate = validationMixin;

// re-export built-in vuelidate validators
export const required = vv.required;
export const requiredIf = vv.requiredIf;
export const requiredUnless = vv.requiredUnless;
export const minLength = vv.minLength;
export const maxLength = vv.maxLength;
export const minValue = vv.minValue;
export const maxValue = vv.maxValue;
export const between = vv.between;
export const email = vv.email;
export const integer = vv.integer;
export const decimal = vv.decimal;
export const url = vv.url;
export const sameAs = vv.sameAs;
export const notSameAs = function(locator){ return vv.not(vv.sameAs(locator)) };
export const regex = function(regex){ return vv.helpers.regex('regex',regex); };


//----------------------------------------------------------------------------
// custom validator definitions
// (based on https://vuelidate.netlify.com/#sub-accessing-component)
//----------------------------------------------------------------------------

//! Helper for conditional validation.
//! Evaluates `validator` function only if model's property (or function) evaluates to true.
//! @param prop string reference of a model property or a function
//! @param validator Vuelidate's built-in or a custom validator
//! Usage:
//!    validations: {
//!        minLength: validateIf('bool_value', minLength(5))
//!    }
export const validateIf = (prop, validator) =>
    vv.helpers.withParams(
        {type:'validateIf', prop},
        function(value, parentVm) {
            return vv.helpers.ref(prop, this, parentVm) ? validator(value) : true;
        }
    );

export const alwaysTrue = (value) => true;



//! ASU personal number validator
export const personalNumber = vv.between(7007001,7007999);


// year validator
export const year = vv.between(1900,2999);

// phone number validator
export const token = vv.helpers.regex('regex',/^[a-zA-Z_]+$/);
export const phonenumber = vv.helpers.regex('regex',/^\d{9}$/);
export const phonenumber_international = vv.helpers.regex('regex',/^[+]?[0-9]{9,15}$/);



// date validator with a custom format
export const date = function(format) {
    if (!format) format='YYYY/MM/DD';  // implicit asis date format
    return function(value) { return !vv.helpers.req(value) || moment(value,format,true).isValid(); }
    // http://momentjs.com/docs/#/parsing/string-format/
};

// date validator with a custom format
export const time = function(format) {
    if (!format) format='HH:mm:ss';  // implicit asis time format
    return function(value) { return !vv.helpers.req(value) || moment(value,format,true).isValid(); }
    // http://momentjs.com/docs/#/parsing/string-format/
};


// asis-formated date validator
export const asis_date = (value) => (!vv.helpers.req(value) || moment(value,'YYYY/MM/DD',true).isValid());

// asis-formated date validator
export const asis_datetime = (value) => (!vv.helpers.req(value) || moment(value,'YYYY/MM/DD HH:mm:ss',true).isValid());

// date-after-date validator
// - both reference date and value are supposed to be strings of a given format
// resources:
// - https://vuelidate.netlify.com/#sub-props-support (withParams usage)
// - http://momentjs.com/docs/#/parsing/string-format/
export const dateAfter = (date, format) => 
    vv.helpers.withParams(
        {type: 'dateAfter', referenceDate:date},
        function(value, parentVm) { 
            if (!format) format='YYYY/MM/DD';  // implicit asis date format
            // return true if value is empty
            if (!vv.helpers.req(value)) return true;
            // parse the two dates
            var refDate = vv.helpers.ref(date, this, parentVm);
            var d1 = moment(refDate, format, true);
            var d2 = moment(value, format, true);
            return d1.isValid() && d2.isValid() && d2.isAfter(d1);
        }
    );

// date-after-date validator
// - both reference date and value are supposed to be strings of a given format
// resources:
// - https://vuelidate.netlify.com/#sub-props-support (withParams usage)
// - http://momentjs.com/docs/#/parsing/string-format/
export const datetimeAfter = (datetime, format) => 
    vv.helpers.withParams(
        {type: 'datetimeAfter', referenceDatetime:datetime},
        function(value, parentVm) { 
            if (!format) format='YYYY/MM/DD HH:mm:ss';  // implicit asis datetime format
            // return true if value is empty
            if (!vv.helpers.req(value)) return true;
            // parse the two dates
            var refDatetime = vv.helpers.ref(datetime, this, parentVm);
            var d1 = moment(refDatetime, format, true);
            var d2 = moment(value, format, true);
            return d1.isValid() && d2.isValid() && d2.isAfter(d1);
        }
    );

// validator for a date that is on this day or later
export const dateInFuture = (format) => {
    if (!format) format='YYYY/MM/DD';  // implicit asis date format
    return function(value) {
        var ref = moment().startOf('day');
        return (!vv.helpers.req(value) || moment(value,format,true).isAfter(ref));
    }
}


// validator for a json string based on regex
// (https://stackoverflow.com/questions/3710204/how-to-check-if-a-string-is-a-valid-json-string-in-javascript-without-using-try)
export const json =
    (value) => 
        (!vv.helpers.req(value) || 
        (/^[\],:{}\s]*$/.test(text.replace(/\\["\\\/bfnrtu]/g, '@').
            replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
            replace(/(?:^|:|,)(?:\s*\[)+/g, ''))
        ));
    



// file object
// with optional extension list
export const file = function(extensions) {
    return vv.helpers.withParams(
        {type: 'file'},
        (value) => { 
            // return true if value is empty
            if (!vv.helpers.req(value) && (!value)) return true;
            // check if value is File object and (optionally) it extension matches
            var ext = (value instanceof File) ? value.name.split('.').pop().toLowerCase() : null;
            return (value instanceof File) && (!extensions || extensions.includes(ext));
        }
    );
};
//orig: return (value) => ( (!vv.helpers.req(value) && (value==null)) || (value instanceof File) );



// full name validator
// - the name has to be given in the format: last_name, first_name
export const fullNameWithComma = 
    (value) => (!vv.helpers.req(value) || /^.+,\s.+$/.test(value));


// time range validator
// checks for input formated as HH:MM-HH:MM (H:MM format is also accepted) and verifies range for hours (0-23) and minutes (0-59)
export const timeRange = 
    vv.helpers.withParams(
        {type:'timeRange'},
        (value) => {
            // return true if value is empty ()
            if (!vv.helpers.req(value)) return true;
            // split string to components
            var times = value.split("-");
            if (times.length != 2) return false;
            // parse the two dates
            var t1 = moment(times[0],'H:mm',true);
            var t2 = moment(times[1],'H:mm',true);
            return t1.isValid() && t2.isValid() && t2.isAfter(t1);
        }
        //!vv.helpers.req(value) || value.indexOf('cool') >= 0
);


// date validator with a custom format
export const maxWords = function(count) {
    return vv.helpers.withParams(
        {type: 'maxWords'},
        (value) => { 
            var wordCount = 0;
            var words = (value||'').split(" ");
            for (let i=0; i<words.length; i++) if (words[i].length > 3) wordCount++;
            return !vv.helpers.req(value) || (wordCount<=count); 
        }
    );
};


// validator for ORCID ID
// (based on https://github.com/harunurhan/is-valid-orcid-js/blob/master/src/index.ts)
export const orcidid =
    (value) => {
        if (!vv.helpers.req(value)) return true;
        if (!value || value.length < 19) return false;
        var digits = value.replace(/-/g, '');
        if (digits.length !== 16) return false;
        var baseDigits = digits.slice(0, 15).split('');
        var checkDigit = digits.charAt(15);
        var expectedCheckDigit = function(_baseDigits) {
            let total = _baseDigits.reduce((acc, digit) => (acc + parseInt(digit, 10)) * 2, 0);
            let remainder = total % 11;
            let result = (12 - remainder) % 11;
            return result === 10 ? 'X' : result.toString();
        }(baseDigits);
        return checkDigit === expectedCheckDigit;
    };
